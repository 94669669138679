<template>
  <module
    ref="module"
    id="stats"
    titleIcon="fa fa-share"
    :title="$t('adbanners')"
    :use-default-list="false"
  >
    <div slot="global">
      <modal :show.sync="openHowTo" modalClasses="modal-lg" :required="true" headerClasses="justify-content-center">
        <template slot="header"></template>
        <template slot="body"></template>
        <template slot="footer">
          <p-button type="default" link @click.prevent="openHowTo = false">{{ $t('ok') }}</p-button>
        </template>
      </modal>
      <modal :show.sync="modalBanner" modalClasses="modal-lg" :required="true" headerClasses="justify-content-center">
        <template slot="header" class="title title-up">
          <span>{{ $t('banner') }}</span>
        </template>
        <template class="body">
          <div style="min-height: 220px">
            <img :src="imageModel.path" :width="imageModel.width" :height="imageModel.height" onContextMenu="return false;"  class="center">
          </div>
        </template>
        <template slot="footer">
          <div class="left-side">
            <p-button type="success" link @click.prevent="downloadImage()">{{ $t('downloadtxt') }}</p-button>
          </div>
          <div class="divider"></div>
          <div class="right-side">
            <p-button type="default" link @click.prevent="modalBanner = false">{{ $t('ok') }}</p-button>
          </div>
        </template>
      </modal>
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="alert alert-info">
              <i class="fa fa-info-circle"></i>
              {{ $t('promo_banner_info') }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 mb-4" v-for="item in bannersList">
            <div class="holder">
              <img :src="item.path" width="250" height="250"/>
              <div class="text-center mt-2 mb-2">
                 <p-button link size="lg" v-on:click="openBanner(item)" >
                   {{ item.width }} x {{ item.height }}
                 </p-button>
              </div>
            </div>

         </div>
        </div>
      </div>
    </div>

  </module>
</template>

<script>
import {Modal} from "@/components/UIComponents";

export default {
  name: "Banners",
  components: {
    Modal,
  },
  data () {
    return {
      bannersList : [
        {
          path: '\\static\\img\\banner\\300x250PP-01.jpeg',
          width: '300',
          height: '250',
        },
        {
          path: '\\static\\img\\banner\\300x600.jpeg',
          width: '300',
          height: '600',
        },
        {
          path: '\\static\\img\\banner\\1080x1080.jpeg',
          width: '1080',
          height: '1080',
        },
        {
          path: '\\static\\img\\banner\\336x280PP-01.jpeg',
          width: '336',
          height: '280',
        },
        {
          path: '\\static\\img\\banner\\1080x1920.jpeg',
          width: '1080',
          height: '1920',
        },
        // {
        //   path: '\\static\\img\\banner\\en-general_free-copytrading-1080x1080.jpg',
        //   width: '1080',
        //   height: '1080',
        // },
      ],
      modalBanner: false,
      openHowTo: false,
      imageModel: {
        path: '',
        width: '',
        height: '',
      }
    }
  },
  methods: {
    openBanner(item) {
      this.imageModel = item
      this.modalBanner = true
    },
    downloadImage() {
       const a = document.createElement('a')
        a.href = this.imageModel.path
        a.download = this.imageModel.path.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }
  },
  mounted() {
    var files = fs.readdirSync('/static/img/banner/')
    console.log(files)
  }
}
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.holder {
  /*display: inline;*/
  width: 80%;
  background-color: #ece7e7;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.holder img {
  width: 100%;
  object-fit: cover;
  /*max-height: 200px;*/
  /*max-width: 200px;*/
  /*object-fit: cover;*/
}
</style>
